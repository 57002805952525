import { CreateTradingDocumentPayment, TradingDocument } from "api/trading-documents/models";
import cx from "classnames";
import { Formik } from "formik";
import { getIsoDateFormat } from "utilities";
import { useSelector } from "hooks";
import { validationSchema } from "./validationSchema";
import { Modal } from "components/miloDesignSystem/atoms/modal";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { tradingDocumentsActions } from "api/trading-documents/actions";
import { AddPaymentForm } from "./AddPaymentForm";
import { Button } from "components/miloDesignSystem/atoms/button";
import { MdiAdd } from "components/miloDesignSystem/atoms/icons/MdiAdd";

interface Props {
  close: () => void;
  tradingDocument: TradingDocument;
}

export const AddPaymentModal = ({ close, tradingDocument }: Props) => {
  const me = useSelector(store => store.auth.user!);
  const handleSubmit = tradingDocumentsActions.useSubmitPayment(close, tradingDocument.id);

  const firstTradingDocumentItem = tradingDocument.items?.[0]?.tradingDocumentItems?.[0];

  const initialValues: CreateTradingDocumentPayment = {
    amount: firstTradingDocumentItem ? String(firstTradingDocumentItem.amountWithTax) : "",
    bankTransactionDate: null,
    bankTransactionNumber: "",
    commission: null,
    createdAt: getIsoDateFormat(new Date()),
    createdBy: me,
    currency: tradingDocument.currency,
    kind: "ADVANCE",
    paymentDate: getIsoDateFormat(new Date()),
    paymentNumber: "",
    provider: "",
    tradingDocument: tradingDocument.id,
    type: "ONLINE",
    items: firstTradingDocumentItem
      ? [
          {
            totalAmount: Number(
              (firstTradingDocumentItem.amountWithTax * firstTradingDocumentItem.quantity).toFixed(
                2,
              ),
            ),
            id: firstTradingDocumentItem.id,
            name: firstTradingDocumentItem.name,
            vatRate: firstTradingDocumentItem.vatRate,
            quantity: firstTradingDocumentItem.quantity,
            initialAmount: Number(
              (firstTradingDocumentItem.amountWithTax * firstTradingDocumentItem.quantity).toFixed(
                2,
              ),
            ),
          },
        ]
      : [],
  };

  return (
    <Modal
      close={close}
      isOpen
      title={
        <Typography fontSize="20" fontWeight="700">
          Dodaj płatność
        </Typography>
      }
      width={680}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ handleSubmit, isSubmitting, isValid }) => (
          <form className={cx({ "was-validated": !isValid })} onSubmit={handleSubmit}>
            <AddPaymentForm tradingDocument={tradingDocument} />
            <div className="d-flex align-items-center gap-3 p-3 borderTop">
              <Button
                className="text-uppercase"
                onClick={close}
                size="medium"
                variant="transparent"
              >
                Anuluj
              </Button>
              <Button
                className="text-uppercase"
                isLoading={isSubmitting}
                size="medium"
                startIcon={MdiAdd}
                type="submit"
                variant="deepPurple"
              >
                Dodaj płatność
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </Modal>
  );
};
