import { UUID } from "api/types";
import { TabSubSection } from "components/common/moduleNavigation/components/tabSubSection/TabSubSection";
import { ModuleLink } from "components/common/moduleNavigation/moduleTypes/moduleTypes";
import { MdiLabel } from "components/miloDesignSystem/atoms/icons/MdiLabel";
import { useSelector } from "hooks";
import { ProductionOrdersRouting } from "routes/manufacturingNew/ProductionOrders";

const StagesList = () => {
  const stages = useSelector(store => store.partials.stages);
  const stagesTabDict: Record<UUID, string> = { "list/all": "Wszystkie" };
  stages.forEach(stage => {
    stagesTabDict[`stage-orders/${stage.id}`] = stage.name;
  });

  return <TabSubSection baseUrl="/manufacturing/production-orders" tabs={stagesTabDict} />;
};

export const productionOrders: ModuleLink = {
  url: "production-orders",
  label: "Zlecenia produkcyjne",
  icon: MdiLabel,
  routing: ProductionOrdersRouting,
  subSection: {
    renderer: <StagesList />,
    isExpandedByDefault: true,
  },
};
