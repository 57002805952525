import { useTradingDocument } from "api/trading-documents/hooks";
import { RightPanelHeader, SlidingHeaderWrapper } from "components/utils/drawer";
import { useQuery, useStateModal } from "hooks";
import { ConfirmTradingDocument } from "pages/tradingDocuments/shared/components/rightPanel/ConfirmTradingDocument";
import { ConfirmCheckbox } from "pages/tradingDocuments/shared/components/confirmCheckbox/ConfirmCheckbox";
import { CreateCorrectionManuallyModal } from "./createCorrectionManuallyModal/CreateCorrectionManuallyModal";
import {
  BulkSalesInvoiceConfirmPreview,
  CreateCorrectionModalState,
} from "api/trading-documents/models";
import { MoreInfoButton } from "pages/tradingDocuments/shared/components/rightPanel/moreInfoButton/MoreInfoButton";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip";
import { MdiDownloadPdf } from "components/miloDesignSystem/atoms/icons/MdiDownloadPdf";
import { ReplyModal } from "pages/tradingDocuments/shared/components/actionToolbar/ReplyModal";
import { MdiDownloadXml } from "components/miloDesignSystem/atoms/icons/MdiDownloadXml";
import { MdiKeyboardTab } from "components/miloDesignSystem/atoms/icons/MdiKeyboardTab";
import { tradingDocumentsActions } from "api/trading-documents/actions";
import { Button } from "components/miloDesignSystem/atoms/button";
import { MdiAdd } from "components/miloDesignSystem/atoms/icons/MdiAdd";
import { useScrollableContext } from "components/common/triggerScrollContext/TriggerScrollContext";
import { DownloadRevisor } from "pages/tradingDocuments/shared/components/DownloadRevisor";

interface Props {
  close: () => void;
}

export const PanelHeader = ({ close }: Props) => {
  const { query } = useQuery();
  const { panelId } = query;
  const { data: salesInvoice } = useTradingDocument({ id: panelId });
  const replyModal = useStateModal<BulkSalesInvoiceConfirmPreview>();
  const handleDownloadOptimaTradeModuleXml = tradingDocumentsActions.useDownloadOptimaTradeModuleXml();
  const handleDownloadOrderXml = tradingDocumentsActions.useDownloadOrderXml();
  const handleSalesInvoicePdfDownload = tradingDocumentsActions.useInvoicePdfDownload();
  const createCorrectionManuallyModal = useStateModal<CreateCorrectionModalState | null>();
  const previewCorrectionMutation = tradingDocumentsActions.useCorrectionPreview(
    createCorrectionManuallyModal.open,
  );
  const { isTriggerElementHidden } = useScrollableContext();

  if (!salesInvoice) return null;

  return (
    <>
      <RightPanelHeader>
        {isTriggerElementHidden ? (
          <SlidingHeaderWrapper className="gap-2">
            <ConfirmCheckbox tradingDocument={salesInvoice} replyModal={replyModal} />
            <div>Faktura VAT&nbsp;{salesInvoice.signature}</div>
          </SlidingHeaderWrapper>
        ) : (
          <ConfirmTradingDocument tradingDocument={salesInvoice} />
        )}
        <div className="d-flex align-items-center justify-content-end gap-1">
          {salesInvoice.status === "CONFIRMED" && (
            <Button
              className="mr-1 text-uppercase"
              isLoading={previewCorrectionMutation.isLoading}
              onClick={() =>
                previewCorrectionMutation.mutate({
                  id: salesInvoice.id,
                  expectedPaymentForm: salesInvoice.expectedPaymentForm,
                })
              }
              size="small"
              startIcon={MdiAdd}
              variant="gray"
            >
              Utwórz korektę
            </Button>
          )}
          <Tooltip title="Pobierz XML z danymi faktury sprzedażowej">
            <IconButton
              icon={<MdiDownloadXml size="18" />}
              onClick={() => handleDownloadOrderXml(salesInvoice)}
              variant="transparent"
            />
          </Tooltip>
          <Tooltip title="Pobierz XML moduł handel [optima]">
            <IconButton
              icon={<MdiDownloadXml size="18" />}
              onClick={() => handleDownloadOptimaTradeModuleXml([salesInvoice.id])}
              variant="transparent"
            />
          </Tooltip>
          <Tooltip title="Pobierz PDF z danymi faktury sprzedażowej">
            <IconButton
              icon={<MdiDownloadPdf size="18" />}
              onClick={() => handleSalesInvoicePdfDownload(salesInvoice)}
              variant="transparent"
            />
          </Tooltip>
          <DownloadRevisor tradingDocument={salesInvoice} />

          <MoreInfoButton close={close} invoice={salesInvoice} panelId={panelId} />
          <span className="divider line-divider" />
          <IconButton icon={MdiKeyboardTab} onClick={close} variant="transparent" />
        </div>
      </RightPanelHeader>
      <CreateCorrectionManuallyModal
        close={createCorrectionManuallyModal.close}
        isOpen={createCorrectionManuallyModal.isOpen}
        state={createCorrectionManuallyModal.state}
      />
      {replyModal.isOpen && (
        <ReplyModal bulkInvoiceConfirmation={replyModal.state} close={replyModal.close} />
      )}
    </>
  );
};
