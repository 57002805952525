import { useSelector } from "hooks";
import { ColumnView } from "./columnView/ColumnView";
import { ListView } from "./listView/ListView";
import { useParams } from "react-router-dom";
import { UUID } from "api/types";
import { useManufacturingStage } from "api/manufacturingNew/hooks";
import { manufacturingActions } from "api/manufacturing/actions";
import { queryString } from "utilities";

export const StageBoard = () => {
  const viewType = useSelector(store => store.ui.viewTypes.manufacturingStageBoard ?? "board");
  const { stageId } = useParams<{ stageId: UUID }>();
  const { data: manufacturingStage } = useManufacturingStage(stageId, {
    enabled: Boolean(stageId),
  });
  const { data: defaultFilters } = manufacturingActions.useStageBoardDefaultAttributesKind(
    queryString.stringify({
      schemaStage: stageId,
    }),
  );

  if (!manufacturingStage || !defaultFilters) return null;

  if (viewType === "board") return <ColumnView />;

  return <ListView key={`stage-list-${stageId}`} />;
};
