import styles from "./FilterDropdown.module.css";
import cx from "classnames";
import { useAsyncModalController, useQuery, useToggle } from "hooks";
import { ClickOutsideHandler, DisabledOpacity } from "components/utils";
import { clickOutsideIgnoreClass } from "../constants";
import { ToolbarAsyncModal } from "components/common/ToolbarDateAsyncModal";
import { getStandardDateFormat } from "utilities";
import { RangeType } from "../types";

interface Props {
  label: string;
  name: [string, string];
  options: {
    value: [string, string];
    label: string;
  }[];
  defaultValue: [string, string];
  disabled?: boolean;
  rangeType?: RangeType;
}

const dateModalId = Symbol("modal");
const customRangeModalId = Symbol("customModalId");

export function FilterDropdown({
  options,
  name,
  label,
  defaultValue,
  disabled = false,
  rangeType = "date",
}: Props) {
  const isDateRange = rangeType === "date";
  const { isOpen, close, toggle } = useToggle(false);
  const { query, updateQuery } = useQuery();
  const dateAsyncModal = useAsyncModalController(dateModalId);
  const customRangeAsyncModal = useAsyncModalController(customRangeModalId);
  const selectedOption = options.find(
    option => option.value[0] === query[name[0]] && option.value[1] === query[name[1]],
  );

  const defaultOption = options.find(
    option => option.value[0] === defaultValue[0] && option.value[1] === defaultValue[1],
  );

  const displayLabel = (() => {
    const areBothSelected = query[name[0]] && query[name[1]];

    return (
      selectedOption?.label ||
      (areBothSelected && formatLabel([query[name[0]], query[name[1]]], isDateRange)) ||
      defaultOption?.label
    );
  })();

  return (
    <ClickOutsideHandler onClickOutside={close} outsideClickIgnoreClass={clickOutsideIgnoreClass}>
      <div className={cx("mr-1", styles.filter)}>
        <DisabledOpacity
          disabled={disabled}
          title="Ta funkcjonalność jest w przygotowaniu"
          className="d-flex align-items-center"
        >
          <button
            type="button"
            className="d-flex align-items-center"
            aria-haspopup="menu"
            onClick={toggle}
          >
            <strong className={cx("mr-1", styles.filterLabel)}>{label}:</strong>
            <strong className={styles.selected}>{displayLabel}</strong>
          </button>
        </DisabledOpacity>
        <div
          className={styles.optionsList}
          role="menu"
          style={{ display: isOpen ? "block" : "none" }}
        >
          {options.map(option => (
            <div
              key={option.label}
              className={styles.optionsItem}
              role="menuitem"
              onClick={async () => {
                updateQuery({ [name[0]]: option.value[0], [name[1]]: option.value[1] });
                close();
              }}
            >
              <strong>{option.label}</strong>
            </div>
          ))}
          <div
            className={styles.optionsItem}
            role="menuitem"
            onClick={async () => {
              const value = isDateRange
                ? await dateAsyncModal.open()
                : await customRangeAsyncModal.open();
              updateQuery({ [name[0]]: value[0], [name[1]]: value[1] });
              close();
            }}
          >
            <strong>{isDateRange ? "Własna data" : "Własny zakres"}</strong>
          </div>
        </div>
        <ToolbarAsyncModal
          id={isDateRange ? dateModalId : customRangeModalId}
          rangeType={rangeType}
        />
      </div>
    </ClickOutsideHandler>
  );
}

function formatLabel(values: [string, string], isDateRange: boolean) {
  function format(date: string) {
    if (!date) return "";
    try {
      return getStandardDateFormat(date);
    } catch (err) {
      return "???";
    }
  }
  const from = isDateRange ? format(values[0]) : values[0];
  const to = isDateRange ? format(values[1]) : values[1];
  return `${from ? "od" : ""} ${from} ${to ? "do" : ""} ${to}`;
}
